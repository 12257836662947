









import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import { State as AppointmentState } from '@/store/appointment'
import AppointmentDetail from '@/components/AppointmentDetail.vue'
import { Step } from '@/steps/index'

const appointmentState = namespace('appointment')

@Component({
  name: 'Step7',
  components: { AppointmentDetail }
})
export default class Step7 extends Vue implements Step {
  @appointmentState.State(state => state.confirmCustomerAppointmentFailure) confirmCustomerAppointmentFailure!: boolean | null

  public nextLabel = 'Bevestigen'
  public valid = true

  @Watch('confirmCustomerAppointmentFailure')
  onConfirmCustomerAppointmentFailure(newValue: AppointmentState['confirmCustomerAppointmentFailure']) {
    if (newValue === true) {
      this.$emit('submitted', false)
    } else if (newValue === false) {
      this.$emit('submitted', true)
    }
  }

  validate() {
    this.$emit('validated', this.valid)
  }

  submit() {
    this.$store.dispatch('appointment/confirmCustomerAppointment')

    window.dataLayer.push({
      event: 'orderProfile',
      data: {
        email: this.$store.state.contactDetails.email
      }
    })
  }
}
