






































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import extractErrorMessage from '@/utils/errorMessages'
import HelpComponent from '@/components/generic/HelpComponent.vue'
import { parseNumber, parseNumberToNational } from '@/utils/phoneNumber'

const props = {
  value: { },
  placeholder: String,
  hint: String,
  v: Object,
  uppercase: Boolean
}

const PhonenumberProps = Vue.extend({ props })
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

@Component({
  components: { HelpComponent },
  props
})
export default class Phonenumber extends PhonenumberProps {
  selectedCountry = 'NL'

  SVGs = {
    NL: require('@/assets/svg/nl.svg'),
    DE: require('@/assets/svg/de.svg'),
    BE: require('@/assets/svg/be.svg')
  }

  mounted() {
    if (typeof this.value === 'string') {
      // Magic
      // Parse the E164 number from the API, since that is a string we will get the country code
      const parsedNumber = parseNumber(this.value)

      // Since showing +41 is not very user friendly, determine the country code and display the National variant
      const newValue = {
        number: parseNumberToNational({
          number: this.value,
          // ZZ = unknown country
          country: 'ZZ'
        }),
        // Since we parsed the E164 number, we can get the country code
        country: phoneUtil.getRegionCodeForNumber(parsedNumber)
      }
      this.$emit('input', newValue)
      this.selectedCountry = newValue.country
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.selectedCountry = this.value?.country ?? 'NL'
    }
  }

  onBlur(event: Event) {
    const target = event.target as HTMLInputElement
    this.$emit('input',
      {
        country: this.selectedCountry,
        number: target?.value ?? null
      })

    if (this.v) {
      this.v.$touch()
      const input = this.$refs.inputField as HTMLInputElement
      if (this.v.$error) {
        input.setCustomValidity(extractErrorMessage(this.v))
      } else {
        input.setCustomValidity('')
      }
    }
  }

  @Watch('selectedCountry')
  onCountryChange(country: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore,@typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.value.country = country
    this.v.$touch()
  }

  extractErrorMessage(v: object): string {
    return extractErrorMessage(v)
  }

  focus() {
    const inputField = this.$refs.inputField as HTMLInputElement
    inputField.focus()
  }
}
