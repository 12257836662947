



















import Vue from 'vue'
import Component from 'vue-class-component'

const props = {
  options: {
    type: Array
  },
  value: {}
}
const RadioProps = Vue.extend({ props })
@Component({ props })
export default class Radio extends RadioProps {

}
