
















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import extractErrorMessage from '@/utils/errorMessages'
import HelpComponent from '@/components/generic/HelpComponent.vue'

const props = {
  value: String,
  placeholder: String,
  v: Object,
  hint: String,
  options: Array
}

const TextInputAutoCompleteOptions = Vue.extend({ props })

@Component({
  components: { HelpComponent },
  props
})
export default class TextInputAutoComplete extends TextInputAutoCompleteOptions {
  optionsStartingWithInput: string[] = []
  selectedOption: number | null = null
  open = false

  mounted() {
    this.recalculateOptions()
  }

  onChange(event: Event) {
    const target = event.target as HTMLInputElement
    this.$emit('input', target?.value ?? null)
    if (this.v) {
      this.v.$touch()
      const input = this.$refs.inputField as HTMLInputElement
      if (this.v.$error) {
        input.setCustomValidity(extractErrorMessage(this.v))
      } else {
        input.setCustomValidity('')
      }
    }
  }

  setValue(value: string) {
    this.$emit('input', value ?? null)
    const inputField = this.$refs.inputField as HTMLInputElement
    inputField.blur()
  }

  extractErrorMessage(v: object): string {
    return extractErrorMessage(v)
  }

  @Watch('options', { immediate: true, deep: true })
  @Watch('value')
  recalculateOptions() {
    const options = [...this.options]
    let filteredOptions = options.splice(0, 5)
    if (this.value) {
      filteredOptions = options.filter(
        (item: unknown) => item && typeof item === 'string' && item.startsWith(this.value)
      ).sort().slice(0, 5) as string[]
    }

    if (filteredOptions.length === 1) {
      return []
    }

    if (this.selectedOption !== null && this.selectedOption > filteredOptions.length - 1) {
      this.selectedOption = filteredOptions.length
    }

    this.optionsStartingWithInput = filteredOptions as string[]
  }

  arrowDown() {
    if (this.selectedOption === null) {
      this.selectedOption = 0
    }
    this.selectedOption++
    if (this.selectedOption >= this.optionsStartingWithInput.length) {
      this.selectedOption--
    }
  }

  tempOpen() {
    this.open = true
    setTimeout(() => {
      this.open = false
    }, 125)
  }

  arrowUp() {
    if (this.selectedOption === null) {
      this.selectedOption = 0
    }
    this.selectedOption--
    if (this.selectedOption < 0) {
      this.selectedOption = 0
    }
  }

  selectOption() {
    if (this.selectedOption === null) {
      return
    }

    this.$emit('input', this.optionsStartingWithInput[this.selectedOption])
    const inputField = this.$refs.inputField as HTMLInputElement
    inputField.blur()
  }
}
