






















import Vue from 'vue'
import Component from 'vue-class-component'

import { stripPhoneNumber } from '@/utils'

@Component
export default class Header extends Vue {
  mounted() {
    this.$store.dispatch('fetchGeneralPhoneNumber')
  }

  get phoneNumber() {
    return this.$store.getters.getPhoneNumber
  }

  get strippedPhoneNumber() {
    return stripPhoneNumber(this.phoneNumber)
  }
}
