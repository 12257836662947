import { Commit } from 'vuex'

import StepDefinition from '@/steps'

export interface State {
  currentStep: number;
  totalSteps: number;
  nextButtonShouldShow: boolean;
  displayProgressBar: boolean | null;
}

export default {
  namespaced: true,
  state: {
    currentStep: 1,
    totalSteps: Object.keys(StepDefinition).length,
    nextButtonShouldShow: true,
    displayProgressBar: true
  },
  mutations: {
    updateTotalSteps(state: State, value: number) {
      state.totalSteps = value
    },
    updateCurrentStep(state: State, value: number) {
      state.currentStep = value
    },
    updateNextButtonShouldShow(state: State, value: boolean) {
      state.nextButtonShouldShow = value
    },
    updateDisplayProgressBar(state: State, value: boolean) {
      state.displayProgressBar = value
    }
  },
  getters: {
    nextStepAvailable: (state: State) => {
      return state.totalSteps > state.currentStep
    },
    previousStepAvailable: (state: State) => {
      return state.currentStep > 1
    }
  },
  actions: {
    nextStep({ commit, state }: { commit: Commit; state: State }) {
      let nextStep = state.currentStep + 1

      if (nextStep > state.totalSteps) {
        nextStep = state.totalSteps
      }

      commit('updateCurrentStep', nextStep)
    },
    previousStep({ commit, state }: { commit: Commit; state: State }) {
      let previousStep = state.currentStep - 1

      if (previousStep < 1) {
        previousStep = 1
      }

      commit('updateCurrentStep', previousStep)
    }
  }
}
