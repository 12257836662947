


















import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { required } from 'vuelidate/lib/validators'

import { Step } from '@/steps/index'
import TextArea from '@/components/generic/TextArea.vue'
import { ProblemAreaResponse } from '@/api/types'
import problemAreas from '@/store/problemAreas'

const problemAreasNamespace = namespace('problemAreas')

@Component({
  name: 'Step4',
  components: { TextArea },
  validations() {
    const validations = { remarks: {} }

    const problemAreaOther = problemAreas.state.selectedProblemAreas.find((problemArea: ProblemAreaResponse) => problemArea.value === '9999')
    if (problemAreaOther) {
      validations.remarks = { required }
    }

    return validations
  }
})
export default class Step4 extends Vue implements Step {
  @problemAreasNamespace.State(state => state.setProblemAreasFailure) setProblemAreasFailure!: boolean | null

  mounted() {
    this.onRemarksChange()

    const remarks = this.$refs.remarks as TextArea
    remarks.focus()
  }

  get remarks() {
    return this.$store.state.problemAreas.remarks
  }

  set remarks(value) {
    this.$store.commit('problemAreas/updateRemarks', value)
  }

  get valid() {
    return !this.$v.$invalid
  }

  @Watch('remarks')
  onRemarksChange() {
    this.$emit('validated', this.valid)
  }

  @Watch('setProblemAreasFailure')
  onSetProblemAreasFailureChange() {
    if (this.setProblemAreasFailure === false) {
      this.$emit('submitted', true)
    } else if (this.setProblemAreasFailure === true) {
      this.$emit('submitted', false)
    }
  }

  validate() {
    this.$v.$touch()
    this.$emit('validated', this.valid)
  }

  submit() {
    this.$store.dispatch('problemAreas/setProblemAreas')
  }
}
