

















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import Checkbox from '@/components/generic/Checkbox.vue'
import ButtonTextBelow from '@/components/generic/buttons/ButtonTextBelow.vue'
import CallToAction from '@/components/generic/buttons/CallToAction.vue'
import { Step } from '@/steps/index'
import { stripPhoneNumber } from '@/utils'
import { ERROR_TOAST } from '@/utils/toasts'

const problemsNamespace = namespace('problems')
const priceNamespace = namespace('price')

@Component({
  name: 'Step2',
  components: {
    Checkbox,
    ButtonTextBelow,
    CallToAction
  }
})
export default class Step2 extends Vue implements Step {
  @problemsNamespace.State(state => state.setProblemsFailure) setProblemsFailure!: boolean | null
  @Getter('getPhoneNumber') phoneNumber!: string

  @priceNamespace.State(state => state.priceText) priceText!: string | null
  @priceNamespace.State(state => state.priceTextFailure) priceTextFailure!: boolean | null

  public selectedProblem = null

  mounted() {
    this.onSelectedProblemChange()
    this.$store.dispatch('price/fetchAppointmentPrice')
  }

  onPhoneNumberClick() {
    window.location.href = 'tel:' + stripPhoneNumber(this.phoneNumber)
  }

  @Watch('priceTextFailure')
  onPriceTextFailureChange(value: boolean | null) {
    if (value === true) {
      this.$bvToast.toast('Prijsinformatie kon niet worden opgehaald. probeer het later opnieuw', ERROR_TOAST)
    }
  }

  @Watch('selectedProblem')
  onSelectedProblemChange() {
    this.$store.commit('wizard/updateNextButtonShouldShow', this.selectedProblem === 1)
    this.$emit('validated', this.valid)
  }

  get valid() {
    return this.selectedProblem === 1
  }

  validate() {
    this.$emit('validated', this.valid)
  }

  submit() {
    this.$store.dispatch('problems/setProblems')
  }

  @Watch('setProblemsFailure')
  OnSetProblemsFailureChange() {
    if (this.setProblemsFailure === false) {
      this.$emit('submitted', true)
    } else if (this.setProblemsFailure === true) {
      this.$emit('submitted', false)
    }
  }
}
