


























import Vue from 'vue'
import Component from 'vue-class-component'

import { getIconByKey, iconKeyImageMapping } from '@/utils/icons'

const props = {
  icon: {
    type: String,
    validator(val: string) {
      return (Object.keys(iconKeyImageMapping)).includes(val)
    },
    default: 'flood'
  },
  text: {
    required: true,
    type: String
  },
  selected: Boolean,
  h100: Boolean
}
const ButtonTextBelowProps = Vue.extend({
  props
})
@Component({
  props
})
export default class ButtonTextBelow extends ButtonTextBelowProps {
  get src() {
    // We can safely type cast this to a keyof iconMap since the validator is here
    const icon = this.icon as keyof typeof iconKeyImageMapping
    return getIconByKey(icon)
  }
}
