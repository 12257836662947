
































import Component from 'vue-class-component'
import Vue from 'vue'

import HelpComponent from '@/components/generic/HelpComponent.vue'
import extractErrorMessage from '@/utils/errorMessages'

const props = {
  value: Boolean,
  text: String,
  hint: String,
  v: Object
}

const CheckboxProps = Vue.extend({
  props
})
@Component({
  components: { HelpComponent },
  props
})
export default class Checkbox extends CheckboxProps {
  error = false

  toggleChange() {
    const newValue = !this.value
    this.$emit('input', newValue)
    if (this.v) {
      this.v.$touch()
    }
  }

  extractErrorMessage(v: object): string {
    return extractErrorMessage(v)
  }
}
