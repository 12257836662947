import { Commit } from 'vuex'
import { DateTime } from 'luxon'

import { State as RootState } from './index'

import ApiClient from '@/api'
import { AppointmentProposal } from '@/api/types'
import { unique } from '@/utils'

interface Getters {
  getPossibleAppointmentDates: string[];
}

export const maxNumberOfDatesToAllowFetchMoreProposals = 12

export interface State {
  appointmentId: string | null;
  proposals: AppointmentProposal[];
  selectedProposal: AppointmentProposal | null;
  getAppointmentProposalsFailure: boolean | null;
  setAppointmentProposalFailure: boolean | null;
  filterSurchargeProposals: boolean;
}

export default {
  namespaced: true,
  state: {
    appointmentId: null,
    proposals: [],
    selectedProposal: null,
    getAppointmentProposalsFailure: null,
    setAppointmentProposalFailure: null,
    filterSurchargeProposals: false
  },
  mutations: {
    updateAppointmentId(state: State, value: State['appointmentId']) {
      state.appointmentId = value
    },
    updateAppointmentProposals(state: State, value: State['proposals']) {
      state.proposals = value
    },
    updateSelectedProposal(state: State, value: State['selectedProposal']) {
      state.selectedProposal = value
    },
    updateGetAppointmentProposalsFailure(state: State, value: State['getAppointmentProposalsFailure']) {
      state.getAppointmentProposalsFailure = value
    },
    updateSetAppointmentProposalFailure(state: State, value: State['setAppointmentProposalFailure']) {
      state.setAppointmentProposalFailure = value
    },
    updateFilterSurchargeProposals(state: State, value: State['filterSurchargeProposals']) {
      state.filterSurchargeProposals = value
    }
  },
  getters: {
    getPossibleAppointmentDates: (state: State) => {
      const possibleDates = state.proposals.map(item => item.date)
      // Using a set (which can't contain duplicates) to smartly remove duplicate dates
      const deduplicatedDates = [...new Set(possibleDates)]
      return deduplicatedDates
    },
    getPossibleAppointmentsOnDate: (state: State) => (date: string) => {
      const proposalsOnDate = state.proposals.filter(proposal => proposal.date === date)
      if (state.filterSurchargeProposals) {
        return proposalsOnDate.filter(proposal => proposal.surcharge === 0)
      }
      return proposalsOnDate
    },
    getDateHasSurcharges: (state: State) => (date: string) => {
      const proposalsOnDate = state.proposals.filter(proposal => proposal.date === date)
      return proposalsOnDate.filter(proposal => (proposal.surcharge ?? 0) > 0).length
    }
  },
  actions: {
    fetchAppointmentProposals({ commit, rootState }: { commit: Commit; rootState: RootState }) {
      commit('updateGetAppointmentProposalsFailure', null)
      if (rootState.appointment.customerAppointment === null) {
        commit('updateGetAppointmentProposalsFailure', true)
        return console.error('Can\'t fetch appointment proposals if there is no appointment')
      }

      commit('updateAppointmentId', rootState.appointment.customerAppointment?.customerAppointmentId)
      commit('updateAppointmentProposals', [])
      commit('updateSelectedProposal', null)

      return ApiClient.getAppointmentProposals(rootState.appointment.customerAppointment.customerAppointmentId).then((proposals) => {
        commit('updateGetAppointmentProposalsFailure', false)
        commit('updateAppointmentProposals', proposals)
      }).catch(() => {
        commit('updateGetAppointmentProposalsFailure', true)
      })
    },
    setAppointmentProposal({ commit, state, rootState }: { commit: Commit; state: State; rootState: RootState }) {
      commit('updateSetAppointmentProposalFailure', null)

      const appointmentId = rootState.appointment.customerAppointment?.customerAppointmentId

      if (appointmentId && state.selectedProposal) {
        ApiClient.setCustomerAppointmentProposal(appointmentId, state.selectedProposal).then(() => {
          commit('updateSetAppointmentProposalFailure', false)
        }).catch(() => {
          commit('updateSetAppointmentProposalFailure', true)
        })
      } else {
        commit('updateSetAppointmentProposalFailure', true)
      }
    },
    fetchMoreProposals({ commit, state, getters }: { commit: Commit; state: State; getters: Getters }) {
      commit('updateGetAppointmentProposalsFailure', null)

      if (state.appointmentId === null) {
        commit('updateGetAppointmentProposalsFailure', true)
        return console.error('Can\'t fetch appointment proposals if there is no appointment')
      }

      const proposalDates = getters.getPossibleAppointmentDates
      if (proposalDates.length >= maxNumberOfDatesToAllowFetchMoreProposals) {
        return
      }

      const currentRequestDate = proposalDates[proposalDates.length - 1]
      const currentDate = DateTime.fromISO(currentRequestDate, { locale: 'nl' })
      const requestDate = currentDate.plus({ days: 1 }).toISODate()

      return ApiClient.getAppointmentProposals(state.appointmentId, requestDate).then((proposals) => {
        let newProposals = [...state.proposals]
        proposals.forEach(proposal => newProposals.push(proposal))
        newProposals = unique(newProposals, 'id')
        commit('updateAppointmentProposals', newProposals)
      })
    }
  },
  modules: {}
}
