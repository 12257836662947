














import Vue from 'vue'
import Component from 'vue-class-component'

const HelpComponentProps = Vue.extend({
  props: {
    text: String
  }
})
@Component
export default class HelpComponent extends HelpComponentProps {}
