import { Commit } from 'vuex'

import ApiClient from '@/api'
import { State as RootState } from '@/store/index'

export interface State {
  setProblemsFailure: boolean | null;
}

export default {
  namespaced: true,
  state: {
    setProblemsFailure: null
  },
  mutations: {
    updateSetProblemsFailure(state: State, value: boolean) {
      state.setProblemsFailure = value
    }
  },
  actions: {
    setProblems({ commit, rootState }: { commit: Commit; rootState: RootState }) {
      commit('updateSetProblemsFailure', null)

      const appointmentId = rootState.appointment.customerAppointment?.customerAppointmentId
      const problems = [
        {
          name: 'Verstopping',
          value: 'VerStop'
        }
      ]

      if (appointmentId) {
        ApiClient.setCustomerAppointmentProblems(appointmentId, problems).then(() => {
          commit('updateSetProblemsFailure', false)
        }).catch(() => {
          commit('updateSetProblemsFailure', true)
        })
      } else {
        commit('updateSetProblemsFailure', true)
      }
    }
  }
}
