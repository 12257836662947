import { Commit } from 'vuex'

import ApiClient from '@/api'
import { ProblemAreaResponse } from '@/api/types'
import { State as RootState } from '@/store/index'

export interface State {
  problemAreasFailure: boolean;
  problemAreas: ProblemAreaResponse[];
  selectedProblemAreas: ProblemAreaResponse[];
  remarks: string | null;
  setProblemAreasFailure: boolean | null;
}

export default {
  namespaced: true,
  state: {
    problemAreasFailure: false,
    problemAreas: [],
    selectedProblemAreas: [],
    remarks: null,
    setProblemAreasFailure: null
  },
  mutations: {
    updateProblemAreas(state: State, value: [ProblemAreaResponse]) {
      state.problemAreas = value.filter(el => el.state)
    },
    updateSelectedProblemAreas(state: State, value: [ProblemAreaResponse]) {
      state.selectedProblemAreas = value
    },
    updateProblemAreasFailure(state: State, value: boolean) {
      state.problemAreasFailure = value
    },
    updateRemarks(state: State, value: string | null) {
      state.remarks = value
    },
    updateSetProblemAreasFailure(state: State, value: boolean) {
      state.setProblemAreasFailure = value
    }
  },
  actions: {
    fetchProblemAreas({ commit }: { commit: Commit; state: State }) {
      commit('updateProblemAreasFailure', false)
      ApiClient.getProblemAreasInformation().then(response => {
        commit('updateProblemAreas', response)
      }).catch(err => {
        console.error(err)
        commit('updateProblemAreasFailure', true)
      })
    },
    setProblemAreas({ commit, state, rootState }: { commit: Commit; state: State; rootState: RootState }) {
      commit('updateSetProblemAreasFailure', null)

      const appointmentId = rootState.appointment.customerAppointment?.customerAppointmentId

      if (appointmentId) {
        ApiClient.setCustomerAppointmentProblemAreas(appointmentId, state.selectedProblemAreas, state.remarks).then(() => {
          commit('updateSetProblemAreasFailure', false)
        }).catch(() => {
          commit('updateSetProblemAreasFailure', true)
        })
      } else {
        commit('updateSetProblemAreasFailure', true)
      }
    },
    restoreFromAppointmentStatus({ commit, rootState }: { commit: Commit; rootState: RootState }) {
      if (rootState.appointmentStatus.customerAppointment) {
        const problemAreas = rootState.appointmentStatus.customerAppointment.objects
        commit('updateSelectedProblemAreas', problemAreas)
      }
    }
  }
}
