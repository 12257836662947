const errorMapping = {
  required: 'Dit veld is verplicht',
  isPostcode: 'Voer een geldige postcode in',
  isTrue: null,
  isEmail: 'Voer een geldig e-mail adres in',
  isPhoneNumber: 'Voer een geldig telefoonnummer in'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function extractErrorMessage(validatorError: any): string {
  for (const index in Object.keys(errorMapping)) {
    const key = Object.keys(errorMapping)[index]
    if (Object.prototype.hasOwnProperty.call(validatorError, key)) {
      if (!validatorError[key]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        return errorMapping[key] as string
      }
    }
  }
  return 'Error: ' + validatorError
}
