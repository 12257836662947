// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function applyMixins(derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      // Skip constructor overrides since that causes only last exported methods to be available
      if (name === 'constructor') {
        return
      }

      Object.defineProperty(
        derivedCtor.prototype,
        name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment,@typescript-eslint/ban-ts-ignore
        // @ts-ignore
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name)
      )
    })
  })
}
