import axios, { AxiosPromise, AxiosRequestConfig, AxiosStatic } from 'axios'

type Config = {
  baseUrl: string;
}

export abstract class Base {
  private readonly httpClient: AxiosStatic

  constructor(config: Config) {
    this.httpClient = axios
    if (!(config.baseUrl)) {
      throw new Error('Config.baseUrl is required')
    }
    this.httpClient.defaults.baseURL = config.baseUrl
  }

  protected get<T>(url: string, queryParams?: object, additionalConfiguration?: AxiosRequestConfig): AxiosPromise<T> {
    return this._call({
      method: 'get',
      url: url,
      params: queryParams,
      ...additionalConfiguration
    })
  }

  protected post<T>(url: string, data: object, additionalConfiguration?: AxiosRequestConfig): AxiosPromise<T> {
    return this._call({
      method: 'post',
      url: url,
      data: data,
      ...additionalConfiguration
    })
  }

  protected put<T>(url: string, data: object, additionalConfiguration?: AxiosRequestConfig): AxiosPromise<T> {
    return this._call({
      method: 'put',
      url: url,
      data: data,
      ...additionalConfiguration
    })
  }

  protected delete<T>(url: string, data?: object, additionalConfiguration?: AxiosRequestConfig): AxiosPromise<T> {
    return this._call({
      method: 'delete',
      url: url,
      data: data,
      ...additionalConfiguration
    })
  }

  private _call(options: AxiosRequestConfig): AxiosPromise {
    return this.httpClient(options)
  }
}
