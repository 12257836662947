import { Base } from '@/api/base'
import { ProblemPriceResponse } from '@/api/types'

export class ProblemPrice extends Base {
  getProblemPriceInformation(problem: string) {
    return new Promise<ProblemPriceResponse>((resolve, reject) => {
      this.get<ProblemPriceResponse>(`/resource/${problem}`).then(res => {
        resolve(res.data)
      }).catch(err => {
        console.error(err)
        reject(err)
      })
    })
  }
}
