// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { phoneNumber } from '@/api/types'

const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export function parseNumber(phoneNumber: string | phoneNumber) {
  if (!phoneNumber) {
    console.error('empty object passed to parseNumber')
    return null
  }
  if (typeof phoneNumber === 'string') {
    if (phoneNumber.startsWith('+')) {
      return phoneUtil.parse(phoneNumber, '')
    } else {
      console.error('Invalid phone number, needs to be phoneNumber object or E.164 formatted string')
      return null
    }
  } else {
    if (!phoneNumber.number || !phoneNumber.country) {
      console.error('Warning, invalid use of parseNumber detected, required object with property "number" and "country", got: ', phoneNumber)
      return null
    }
    return phoneUtil.parseAndKeepRawInput(phoneNumber.number, phoneNumber.country)
  }
}

export function parseNumberToE164(phoneNumber: phoneNumber): string | null{
  const number = parseNumber(phoneNumber)
  return phoneUtil.format(number, PNF.E164)
}

export function parseNumberToNational(phoneNumber: phoneNumber): string | null{
  const number = parseNumber(phoneNumber)
  return phoneUtil.format(number, PNF.NATIONAL)
}

