




















import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import Checkbox from '@/components/generic/Checkbox.vue'
import Select, { SelectItem } from '@/components/generic/Select.vue'
import { ProblemAreaResponse } from '@/api/types'
import { Step } from '@/steps/index'
import { getIconKeyByProblemValue } from '@/utils/icons'

const problemAreasState = namespace('problemAreas')

@Component({
  components: {
    Checkbox,
    Select
  }
})
export default class Step3 extends Vue implements Step {
  @problemAreasState.State(state => state.problemAreasFailure) problemAreasFailure!: boolean
  @problemAreasState.State(state => state.problemAreas) problemAreas!: [ProblemAreaResponse]

  mounted() {
    this.$store.dispatch('problemAreas/fetchProblemAreas')
  }

  get selectedItems() {
    return this.$store.state.problemAreas.selectedProblemAreas.map((item: ProblemAreaResponse) => item.value)
  }

  set selectedItems(selectedValues: unknown[]) {
    this.$store.commit(
      'problemAreas/updateSelectedProblemAreas',
      this.problemAreas.filter(item => selectedValues.indexOf(item.value) > -1)
    )
  }

  @Watch('selectedItems')
  onSelectedItemsChange() {
    this.$emit('validated', this.valid)
  }

  get problemAreaList(): SelectItem[] {
    return this.problemAreas.map(item => {
      return {
        value: item.value,
        name: item.name,
        icon: getIconKeyByProblemValue(item.value)
      }
    })
  }

  get valid() {
    return this.selectedItems.length >= 1
  }

  validate() {
    this.$emit('validated', this.valid)
  }

  submit() {
    this.$emit('submitted', this.valid)
  }
}
