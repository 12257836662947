import Vue from 'vue'

import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import Step3 from './Step3.vue'
import Step4 from './Step4.vue'
import Step5 from './Step5.vue'
import Step6 from './Step6.vue'
import Step7 from './Step7.vue'

export const StepDefinition: { [key: number]: typeof Vue } = {
  1: Step1,
  2: Step2,
  3: Step3,
  4: Step4,
  5: Step5,
  6: Step6,
  7: Step7
}

export interface Step {
  nextLabel?: string;
  valid: boolean;

  validate(): void;

  submit(): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function implementsStepInterface(object: any): object is Step {
  return 'submit' in object
}

export default StepDefinition
