import { Base } from '@/api/base'
import { ProblemAreaResponse } from '@/api/types'

export class ProblemAreas extends Base {
  getProblemAreasInformation() {
    return new Promise<Array<ProblemAreaResponse>>((resolve, reject) => {
      return this.get<Array<ProblemAreaResponse>>('/code/orderobject').then(response => {
        response.data.sort((a, b) => Math.sign(a.order - b.order))
        resolve(response.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
