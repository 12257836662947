import { DateTime } from 'luxon'

export function stripPhoneNumber(phoneNumber: string) {
  return phoneNumber.replaceAll(' ', '').replaceAll('-', '')
}

export function displayDate(date: string) {
  return DateTime.fromISO(date, { locale: 'nl' }).toLocaleString({
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  })
}

export function displayDateWithoutYear(date: string) {
  return DateTime.fromISO(date, { locale: 'nl' }).toLocaleString({
    weekday: 'long',
    day: 'numeric',
    month: 'long'
  })
}

export function displayTime(date: string) {
  return DateTime.fromISO(date, { locale: 'nl' }).toLocaleString({
    hour: 'numeric',
    minute: 'numeric'
  })
}

export function unique<Type extends Record<string, unknown>>(items: Type[], property: string): Type[] {
  const result: Type[] = []
  items.filter((item) => {
    const i = result.findIndex(x => x[property] === item[property])
    if (i <= -1) {
      result.push(item)
    }
  })
  return result
}
