// Regex van https://emailregex.com/
// eslint-disable-next-line no-useless-escape
import { phoneNumber } from '@/api/types'
import { parseNumber } from '@/utils/phoneNumber'

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const POSTCODE_REGEX = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/

// Get an instance of `PhoneNumberUtil`.
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export function isPostcode(value: string) {
  if (!value) return true

  return POSTCODE_REGEX.test(value.toLowerCase())
}

export function arrayHasOne(value: never[]) {
  if (!Array.isArray(value)) {
    return false
  }
  return value.length === 1
}

export function isTrue(value: boolean) {
  return value === true
}

export function isEmail(value: string) {
  if (!value) return true

  return EMAIL_REGEX.test(value.toLowerCase())
}

export function isPhoneNumber(value: phoneNumber) {
  try {
    const number = parseNumber(value)
    return (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number))
  } catch (e) {
    return false
  }
}
