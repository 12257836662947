

































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import StepDefinition, { implementsStepInterface, Step } from '@/steps'
import ProgressBar from '@/components/generic/ProgressBar.vue'
import Header from '@/components/generic/Header.vue'
import CallToAction from '@/components/generic/buttons/CallToAction.vue'
import { ERROR_TOAST } from '@/utils/toasts'

@Component({
  components: {
    ProgressBar,
    Header,
    CallToAction
  }
})
export default class Wizard extends Vue {
  private stepValid = false
  private loading = false
  private nextLabel: string | null = null

  mounted() {
    this.onCurrentStepChange()
  }

  get totalSteps() {
    return this.$store.state.wizard.totalSteps
  }

  set totalSteps(value) {
    this.$store.commit('wizard/updateTotalSteps', value)
  }

  get currentStep() {
    return this.$store.state.wizard.currentStep
  }

  get currentStepAlias(): typeof Vue {
    return StepDefinition[this.currentStep]
  }

  get currentStepInstance(): Step | null {
    const step = this.$refs['step-' + this.currentStep] ?? null
    if (step && implementsStepInterface(step)) {
      return step
    }

    return null
  }

  get backAvailable() {
    return this.$store.getters['wizard/previousStepAvailable']
  }

  get nextButtonShouldShow() {
    return this.$store.state.wizard.nextButtonShouldShow
  }

  @Watch('currentStep')
  onCurrentStepChange() {
    this.$nextTick(() => {
      this.stepValid = this.currentStepInstance?.valid ?? false
      this.nextLabel = this.currentStepInstance?.nextLabel ?? 'Volgende'
    })
  }

  handleBack() {
    this.$store.dispatch('wizard/previousStep')
  }

  handleNext() {
    if (this.currentStepInstance) {
      this.currentStepInstance.validate()
      if (this.stepValid) {
        this.loading = true
        this.currentStepInstance.submit()
      }
    }
  }

  handleStepValidated(value: boolean) {
    this.stepValid = value
  }

  handleStepSubmitted(value: boolean) {
    if (value) {
      if (this.currentStep === this.totalSteps) {
        // determine success route based on if a proposal has been selected
        const successRoute = this.$store.state.proposals?.selectedProposal ? 'success' : 'success-plan-date'
        this.$router.push({ name: successRoute })
      } else {
        this.$store.dispatch('wizard/nextStep')
      }
    } else {
      this.$bvToast.toast('Het formulier kon niet opgeslagen worden', ERROR_TOAST)
    }
    this.loading = false
  }
}
