


































































import Vue from 'vue'
import { namespace } from 'vuex-class'
import Component from 'vue-class-component'
import { required } from 'vuelidate/lib/validators'
import { Watch } from 'vue-property-decorator'

import { State as ContactDetailsState } from '@/store/contactDetails'
import { State as AppointmentState } from '@/store/appointment'
import Checkbox from '@/components/generic/Checkbox.vue'
import { Step } from '@/steps/index'
import Radio from '@/components/generic/Radio.vue'
import TextInput from '@/components/generic/TextInput.vue'
import { isEmail, isPhoneNumber, isTrue } from '@/utils/validators'
import HelpComponent from '@/components/generic/HelpComponent.vue'
import TextArea from '@/components/generic/TextArea.vue'
import Phonenumber from '@/components/generic/Phonenumber.vue'

const contactDetailsNamespace = namespace('contactDetails')
const appointmentNamespace = namespace('appointment')
@Component({
  components: {
    Phonenumber,
    HelpComponent,
    TextInput,
    TextArea,
    Radio,
    Checkbox
  },
  validations: {
    name: {
      required
    },
    email: {
      required,
      isEmail
    },
    phoneNumber: {
      required,
      isPhoneNumber
    },
    acceptedConditions: {
      isTrue
    }
  }
})
export default class Step6 extends Vue implements Step {
  @contactDetailsNamespace.State(state => state.setContactDetailsFailure) setContactDetailsFailure!: ContactDetailsState['setContactDetailsFailure']
  @appointmentNamespace.State(state => state.confirmCustomerAppointmentFailure) confirmCustomerAppointmentFailure!: boolean | null

  public nextLabel = ''

  mounted() {
    this.nextLabel = this.isEditingAppointment ? 'Gegevens opslaan' : 'Gegevens controleren'

    this.onAnyFieldChange()

    const name = this.$refs.name as TextInput
    name.focus()
  }

  get isEditingAppointment() {
    return this.$store.getters.getEditingAppointment
  }

  get usedDisintegrant() {
    return this.$store.state.contactDetails.usedDisintegrant
  }

  set usedDisintegrant(value) {
    this.$store.commit('contactDetails/updateUsedDisintegrant', value)
  }

  get statusUpdatesSms() {
    return this.$store.state.contactDetails.statusUpdatesSms
  }

  set statusUpdatesSms(value) {
    this.$store.commit('contactDetails/updateStatusUpdatesSms', value)
  }

  get remarks() {
    return this.$store.state.contactDetails.remarks
  }

  set remarks(value) {
    this.$store.commit('contactDetails/updateRemarks', value)
  }

  get acceptedConditions() {
    return this.$store.state.contactDetails.acceptedConditions
  }

  set acceptedConditions(value) {
    this.$store.commit('contactDetails/updateAcceptedConditions', value)
  }

  get gender() {
    return this.$store.state.contactDetails.gender
  }

  set gender(value) {
    this.$store.commit('contactDetails/updateGender', value)
  }

  get name() {
    return this.$store.state.contactDetails.name
  }

  set name(value) {
    this.$store.commit('contactDetails/updateName', value)
  }

  get email() {
    return this.$store.state.contactDetails.email
  }

  set email(value) {
    this.$store.commit('contactDetails/updateEmail', value)
  }

  get phoneNumber() {
    return this.$store.state.contactDetails.phoneNumber
  }

  set phoneNumber(value) {
    this.$store.commit('contactDetails/updatePhoneNumber', value)
  }

  get valid() {
    return !this.$v.$invalid
  }

  @Watch('gender')
  @Watch('name')
  @Watch('email')
  @Watch('phoneNumber')
  @Watch('remarks')
  @Watch('acceptedConditions')
  onAnyFieldChange() {
    // only touch if all fields are ready to be touched (have input)
    if (this.gender && this.name && this.email) {
      this.$v.$touch()
    }

    this.$emit('validated', this.valid)
  }

  @Watch('confirmCustomerAppointmentFailure')
  onConfirmCustomerAppointmentFailure(newValue: AppointmentState['confirmCustomerAppointmentFailure']) {
    if (newValue === true) {
      this.$emit('submitted', false)
    } else if (newValue === false) {
      if (this.isEditingAppointment) {
        const appointmentId = this.$store.state.appointment.customerAppointment.customerAppointmentId ?? ''
        const postCode = this.$store.state.address.postCode ?? ''
        const houseNumber = this.$store.state.address.houseNumber ?? ''
        const addition = this.$store.state.address.addition ?? ''

        window.location.href = `/afspraakstatus/${appointmentId}/${postCode}/${houseNumber}/${addition}`
      }
    }
  }

  @Watch('phoneNumber')
  onPhoneNumberChange() {
    if (!this.phoneNumber) {
      this.statusUpdatesSms = false
    }
  }

  @Watch('setContactDetailsFailure')
  onSetContactDetailsFailure(newValue: ContactDetailsState['setContactDetailsFailure']) {
    if (newValue === true) {
      this.$emit('submitted', false)
    } else if (newValue === false) {
      if (this.isEditingAppointment) {
        this.$store.dispatch('appointment/confirmCustomerAppointment')
      } else {
        this.$emit('submitted', true)
      }
    }
  }

  getRadioOptions() {
    return [
      {
        code: 'Male',
        name: 'Dhr.'
      },
      {
        code: 'Female',
        name: 'Mevr.'
      }
    ]
  }

  validate() {
    this.$v.$touch()

    this.$emit('validated', this.valid)
  }

  submit() {
    this.$store.dispatch('contactDetails/setContactDetails')
  }

  goBack() {
    const appointmentId = this.$store.state.appointment.customerAppointment.customerAppointmentId ?? ''
    const postCode = this.$store.state.address.postCode ?? ''
    const houseNumber = this.$store.state.address.houseNumber ?? ''
    const addition = this.$store.state.address.addition ?? ''

    this.$router.replace(`/afspraakstatus/${appointmentId}/${postCode}/${houseNumber}/${addition}`)
  }
}
