import { Base } from '@/api/base'
import { DilapidatedReason, ResourceResponse } from '@/api/types'

export class Resources extends Base {
  getResource(resource: string) {
    return this.get<ResourceResponse>(`/resource/${resource}`).then(res => {
      return res.data
    }).catch(err => {
      console.error(err)
      throw err
    })
  }

  getDilapidatedReasons() {
    return this.get<DilapidatedReason[]>('/code/dilapidatedreason').then(res => {
      return res.data
    }).catch(err => {
      console.error(err)
      throw err
    })
  }
}
