




















import Vue from 'vue'
import Component from 'vue-class-component'

import extractErrorMessage from '@/utils/errorMessages'
import HelpComponent from '@/components/generic/HelpComponent.vue'

const props = {
  value: String,
  placeholder: String,
  v: Object,
  hint: String
}

const TextAreaProps = Vue.extend({ props })

@Component({
  components: { HelpComponent },
  props
})
export default class TextArea extends TextAreaProps {
  onChange(event: Event) {
    const target = event.target as HTMLTextAreaElement
    this.$emit('input', target?.value ?? null)
    if (this.v) {
      this.v.$touch()
      const input = this.$refs.inputField as HTMLTextAreaElement
      if (this.v.$error) {
        input.setCustomValidity(extractErrorMessage(this.v))
      } else {
        input.setCustomValidity('')
      }
    }
  }

  extractErrorMessage(v: object): string {
    return extractErrorMessage(v)
  }

  focus() {
    const inputField = this.$refs.inputField as HTMLTextAreaElement
    inputField.focus()
  }
}
