import { Base } from '@/api/base'
import { AddressContract, AddressResponse } from '@/api/types'

export const ADDRESS_FAILURE_NO_ADDRESS = 'NO_ADDRESS'
export const ADDRESS_FAILURE_API_ERROR = 'API_ERROR'

export class Address extends Base {
  fetchAddresses(postalCode: string, houseNumber: string, addition?: string): Promise<Array<AddressResponse>> {
    return this.get<Array<AddressResponse>>('/address', {
      postalCode,
      number: houseNumber,
      // If addition is falsey pass it as undefined, since empty strings are not allowed
      addition: addition || undefined
    }).then(res => {
      if (res.data.length === 0) {
        throw ADDRESS_FAILURE_NO_ADDRESS
      }
      return res.data
    }).catch(err => {
      if (err.response.status === 404) {
        throw ADDRESS_FAILURE_NO_ADDRESS
      } else {
        console.error(err)
        throw ADDRESS_FAILURE_API_ERROR
      }
    })
  }

  getAddressInformation(postalCode: string, houseNumber: string, addition?: string): Promise<AddressResponse> {
    return this.fetchAddresses(postalCode, houseNumber, addition).then((addresses) => {
      for (const address of addresses) {
        if (address.postalCode === postalCode &&
          address.number.toString() === houseNumber &&
          // eslint-disable-next-line eqeqeq
          (address.addition || '').toLowerCase() == (addition || '').toLowerCase()) {
          return address
        }
      }
      throw ADDRESS_FAILURE_NO_ADDRESS
    })
  }

  getAddressAdditions(postalCode: string, houseNumber: string): Promise<string[]> {
    return this.fetchAddresses(postalCode, houseNumber).then((addresses) => {
      return addresses.map(a => a.addition).filter(a => a !== undefined) as string[]
    })
  }

  getContractInformation(postalCode: string, houseNumber: string): Promise<AddressContract> {
    return this.get<AddressContract>('/address/contract', {
      postalCode,
      number: houseNumber
    }).then(res => {
      return res.data
    }).catch(err => {
      if (err.response.status === 404) {
        throw ADDRESS_FAILURE_NO_ADDRESS
      } else {
        console.error(err)
        throw ADDRESS_FAILURE_API_ERROR
      }
    })
  }
}
