

























import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

const props = {
  value: {
    type: Number,
    required: true
  },
  max: {
    type: Number,
    required: true
  },
  showStep: Boolean,
  backAvailable: Boolean
}

const wizardState = namespace('wizard')

const ProgressBarProps = Vue.extend({
  props
})
@Component({ props })
export default class ProgressBar extends ProgressBarProps {
  @wizardState.State(state => state.displayProgressBar) displayProgressBar!: boolean | null

  get barFillPercentage(): number {
    return (100 / this.max) * this.value
  }
}
