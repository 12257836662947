











import Vue from 'vue'
import Component from 'vue-class-component'

const CTAProps = Vue.extend({
  props: {
    secondary: Boolean,
    disabled: Boolean,
    sticky: Boolean,
    loading: Boolean
  }
})

@Component
export default class CallToAction extends CTAProps {
  emitClick() {
    if (this.disabled) {
      return
    }
    this.$emit('click')
  }
}
