import { Base } from './base'

import applyMixins from '@/utils/applyMixins'
import { Address } from '@/api/calls/address'
import { IntroText } from '@/api/calls/introText'
import { Telephone } from '@/api/calls/telephone'
import { ProblemPrice } from '@/api/calls/problemPrice'
import { ProblemAreas } from '@/api/calls/problemAreas'
import { CustomerAppointment } from '@/api/calls/customerAppointment'
import { Resources } from '@/api/calls/resources'

class ApiClient extends Base {
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ApiClient extends Address, IntroText, Telephone, ProblemPrice, ProblemAreas, CustomerAppointment, Resources {
}

applyMixins(ApiClient, [Address, IntroText, Telephone, ProblemPrice, ProblemAreas, CustomerAppointment, Resources])

const client = new ApiClient({ baseUrl: process.env.VUE_APP_API_ENDPOINT })
export default client
